import { zodResolver } from '@hookform/resolvers/zod';
import t, { tt } from '@lib/locales';
import { useForm } from 'react-hook-form';
import * as z from 'zod';

type Props = {
  onDone: (data: any) => void;
};

export const B2BLoginForm: React.FC<Props> = ({ onDone }) => {
  const schema = z
    .object({
      email: z
        .string()
        .min(1, t('en', 'field_required'))
        .email(t('en', 'enter_valid_email'))
        .toLowerCase()
        .trim(),
      password: z.string().min(6, tt('en', 'password_min_chars', { min: '6' })),
    })
    .required({
      email: true,
      password: true,
    });

  type Schema = z.infer<typeof schema>;

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(schema),
  });

  const onSubmit = (data: Schema) => {
    onDone(data);
  };

  return (
    <div className="auth">
      <h4 className="auth__title">Login</h4>
      <div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form__field">
            <label>Email</label>
            <div>
              <input
                className="form__input"
                type="email"
                {...register('email')}
                placeholder="Email"
                autoComplete="email"
              />
            </div>
            {errors.email && <div className="form__error">{errors.email.message.toString()}</div>}
          </div>

          <div className="form__field">
            <label>Password</label>
            <div>
              <input
                className="form__input"
                type="password"
                {...register('password')}
                placeholder="Password"
                autoComplete="off"
              />
            </div>
            {errors.password && (
              <div className="form__error">{errors.password.message.toString()}</div>
            )}
          </div>
          <div className="form__field">
            <button className="button--primary" type="submit">
              Login
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};
