'use client';

import { B2BLoginForm } from '@components/auth/B2bLoginForm';
import config from '@data/config.json';
import { useAuth } from '@lib/b2b/authContext';
import { useAppContext } from '@lib/context/appContext';
import { getPathFromMarket } from '@lib/utils/clUtils';
import { getLocales } from '@lib/utils/pathUtils';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';

export const B2BHome: React.FC = () => {
  const router = useRouter();
  const { state } = useAppContext();
  const [error, setError] = useState<string>('');
  const searchParams = useSearchParams();

  const useAuthParams = useAuth();
  const user = useAuthParams.user;
  const login = useAuthParams.login;

  function detectLocale(defaultLocale: string): string {
    const language = config.configuration.locale.code || defaultLocale;

    const locales = getLocales();

    if (locales.includes(language)) {
      return language;
    }
    return defaultLocale;
  }

  const redirect = (email: string | null) => {
    if (state?.marketId) {
      const { locale: defaultLocale, marketId } = state;
      const locale = detectLocale(defaultLocale);
      let redirectUrl = getPathFromMarket(marketId, locale);
      const search = searchParams.toString();
      if (!!email) {
        redirectUrl += `?${search}utm_source=${email}`;
      }
      if (redirectUrl) {
        router.push(redirectUrl);
      }
    }
  };

  const onSubmit = async (data: { email: string; password: string }) => {
    setError('');
    try {
      const result = await login!(data);
      if (!result) {
        setError('Login failed');
      }
    } catch (error: unknown) {
      if (error instanceof TypeError) {
        setError(error.message);
      }
    }
  };

  useEffect(() => {
    if (user) {
      setTimeout(() => {
        redirect(user.email);
      }, 0);
    }
  }, [user]);

  return (
    <div
      style={{
        marginTop: 20,
        width: '100vw',
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      {!!user ? (
        <div>
          <h3>Hello {user.email}</h3>
        </div>
      ) : (
        <div style={{ maxWidth: 350, padding: 5 }}>
          {error && <div className="form__error">{error}</div>}
          <B2BLoginForm onDone={(data) => onSubmit(data)} />
        </div>
      )}
    </div>
  );
};
