'use client';

import config from '@data/config.json';
import { useAppContext } from '@lib/context/appContext';
import { getPathFromMarket } from '@lib/utils/clUtils';
import { getLocales } from '@lib/utils/pathUtils';
import { isB2BEnabled } from '@utils/config';
import { useRouter, useSearchParams } from 'next/navigation';
import { useEffect, useState } from 'react';
import { B2BHome } from './B2BHome';

export const Home: React.FC = () => {
  const router = useRouter();
  const { state } = useAppContext();
  const [calledPush, setCalledPush] = useState(false);
  const searchParams = useSearchParams();

  function detectLocale(defaultLocale: string): string {
    const language = config.configuration.locale.code || defaultLocale;

    const locales = getLocales();

    if (locales.includes(language)) {
      return language;
    }
    return defaultLocale;
  }

  useEffect(() => {
    if (!isB2BEnabled && state?.marketId) {
      if (calledPush) {
        return; // no need to call router.push() again
      }
      setCalledPush(true);
      const { locale: defaultLocale, marketId } = state;
      const locale = detectLocale(defaultLocale);
      const redirectUrl = getPathFromMarket(marketId, locale);
      const search = searchParams.toString();
      const url = `${redirectUrl}${search ? `?${search}` : ''}`;

      router.push(url);
    }
  }, [state]);

  if (isB2BEnabled) {
    return <B2BHome />;
  }
};
